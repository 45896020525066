/* eslint-disable no-console */
import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import Button from "../custom-widgets/button";
// NOTE: the languageContext is too slow for the menus, so using router location to check URL for ".../es/..."
import { useLocation } from "@reach/router";

// Components
import Icon from "../custom-widgets/icon";
// URL Data
import { englishToSpanish, spanishToEnglish } from "../../data/url-alternates-data";

// Menu Data
import { englishMobileMenusData, spanishMobileMenusData } from "../../data/mobile-menus-data";

// Styles
import "./mobile-menus.bootstrap.scss";

const MobileMenus = (props) => {
  // We are using the location.hash to check for hashchange events
  const location = useLocation();

  // SET THE MENU DATA BY LANGUAGE by detecting the existence of ".../es[/...]" from the page URL
  // We are adding a trailing slash here so this can't be used to find the exact page URL
  let pagePath = useLocation().pathname;
  // Remove trailing slash (/) from the pagePath if it exists
  pagePath = pagePath.replace(/\/$/, "");
  // Now explicitly add a trailing slash to act as a word break for ".../es..."
  pagePath = pagePath + "/";
  const isSpanish = pagePath.indexOf("/es/") >= 0 ? true : false;
  const [menuData, setMenuData] = useState(isSpanish ? spanishMobileMenusData : englishMobileMenusData);

  // the subset of all the menuData that is currently being shown
  const [currentMenuData, setCurrentMenuData] = useState(isSpanish ? spanishMobileMenusData : englishMobileMenusData);
  // Used to navigate back and forth between menus
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  // This path is to compare the page URL and the URLs from the menusData to set the active state
  let path = typeof window !== "undefined" ? window.location.href.replace(window.location.origin, "") : "";
  let hash = location.hash;
  let i = path.indexOf("#");
  if (hash.includes("faq-answer")) {
    path = path.substring(0, i);
    // console.log("removed hash from path: " + path);
  }
  // Remove trailing slash (/) from the path if it exists
  path = path.replace(/\/$/, "");

  useEffect(() => {
    // reset the breadcrumbs
    setBreadcrumbs([]);

    // set the active state on the selected menu item
    const tempMenuData = [...menuData];
    if (Array.isArray(tempMenuData)) {
      tempMenuData.forEach((category) => {
        category.isActive = category?.url === path;
        if (Array.isArray(category?.menus)) {
          category.menus.forEach((area) => {
            area.isActive = area?.url === path;
            if (Array.isArray(area?.menus)) {
              area.menus.forEach((item) => {
                item.isActive = item?.url === path;
              });
            }
          });
        }
      });
    }

    // update the menuData with the isActive properties
    setMenuData(tempMenuData);
  }, [path]); // only when the path changes (page load/navigation)

  // If a user clicks a menu that is a hash to the same page, we need to reset and close the menus.
  useEffect(() => {
    // prevent close on page load of a hashed url by checking if the menus are already open
    if (props.showMenu && location.hash) {
      handleCloseMenu();
    }
  }, [location.hash]);

  // Handle when user clicks on a menu item that has a submenu
  const handleForwardNavigation = (e) => {
    e.preventDefault();
    const menuTitle = e.target?.innerText?.toString();
    // Filter data where the menu item text and title in currentMenuData variable match
    const selectedMenuData = currentMenuData.filter((data) => data.title === menuTitle);
    const selectedMenu = selectedMenuData[0]; // convenience var
    if (selectedMenu !== undefined) {
      let tempBreadcrumbs = [...breadcrumbs];
      // Push data to the beginning of the breadcrumbs array in an object
      tempBreadcrumbs.unshift({
        id: selectedMenu.id + "-label",
        title: e.target.innerText,
        landingTitle: selectedMenu.landingTitle ?? menuTitle,
        landingUrl: selectedMenu.url,
        data: selectedMenu.menus,
        isActive: selectedMenu.url === path ? true : false
      });
      setBreadcrumbs(tempBreadcrumbs);
      // Set currentMenuData with the data selected
      setCurrentMenuData(selectedMenu.menus);
    } else {
      console.warn("selectedMenu is undefined for " + menuTitle);
    }
  };

  // Handle when user clicks the "Back" button
  const handleBackNavigation = () => {
    let tempBreadcrumbs = [...breadcrumbs];
    // Remove first item in breadcrumbs array
    tempBreadcrumbs.shift();
    setBreadcrumbs(tempBreadcrumbs);

    // If breadcrumbs array has at least one element, set currentMenuData to that data, else set it to menuData
    if (tempBreadcrumbs.length > 0) {
      setCurrentMenuData(tempBreadcrumbs[0].data);
    } else {
      setCurrentMenuData(menuData);
    }
  };

  // Handle when user closes the menus
  const handleCloseMenu = () => {
    setBreadcrumbs([]);
    setCurrentMenuData(menuData);
    props.handleClose();
  };

  // TODO: move this property to the data file
  // This is used to display menu items with matching titles differently than others (See comp for specific look)
  const featuredMenuItems = ["banking with us"];

  // Alternate URL Functionality (Start)

  const [altUrl, setAltUrl] = useState(null);

  useEffect(() => {
    const urlMap = isSpanish ? spanishToEnglish : englishToSpanish;
    const newUrl = urlMap[path];

    if (newUrl) {
      setAltUrl(newUrl);
    } else {
      setAltUrl(isSpanish ? "/" : "/es");
    }
  });

  // Alternate URL Functionality (End)

  function openAnAccountUrl() {
    if (path === "/open-bank-account-online") {
      return "/open-an-account";
    } else if (path === "/business-banking/open-business-bank-account-online") {
      return "https://forms.fivision.com/wafdbank/oba/Default.aspx";
    } else if (path.indexOf("business-banking") > 0 || path.indexOf("commercial-banking") > 0) {
      return "/business-banking/open-business-bank-account-online";
    } else return "/open-bank-account-online";
  }

  const openAccountBtn = {
    id: "mm-open-account-button",
    type: "link",
    text: "Open an Account",
    url: openAnAccountUrl(),
    showIcon: false,
    class: "btn btn-primary d-block mb-3"
  };

  return (
    <>
      {/* Background */}
      <div
        onClick={(e) => handleCloseMenu()}
        id="background"
        className="sticky-top position-fixed w-100 vh-100"
        style={{
          top: 0,
          left: props.showMenu ? "0px" : "-100%",
          opacity: props.showMenu ? "100" : "0",
          transition: "opacity .5s",
          background: "rgba(0,0,0,.5)"
        }}
      ></div>
      {/* Menu Container */}
      <div
        id="mobile-menus"
        className="sticky-top position-fixed vh-100"
        style={{
          width: "87%",
          maxWidth: "400px",
          top: "0",
          left: props.showMenu ? "0px" : "-100%",
          transition: ".4s"
        }}
      >
        {/* Section Above Menus */}
        <div className="position-sticky">
          <div className="mobile-menu-close-btn-container" onClick={(e) => handleCloseMenu()}>
            <div
              onClick={(e) => handleCloseMenu()}
              id="mobile-menu-close-btn"
              className="bg-white py-3 px-4 d-inline-block cursor-pointer"
            >
              <Icon lib="fal" name="times" class="fa-lg text-secondary" />
            </div>
          </div>
          <div className="bg-white d-flex align-content-center justify-content-between border-bottom">
            {breadcrumbs.length === 0 ? (
              <>
                <div className="w-100 p-3">
                  {isSpanish ? (
                    <div className="w-100 d-flex align-items-center justify-content-between">
                      <button
                        id="mm-activate-search-modal"
                        onClick={() => props.setShowMobileSearch(true)}
                        className="btn-anchor-link"
                      >
                        <Icon lib="fal" name="search" />
                      </button>
                      <div className="d-flex align-items-center">
                        <Icon class="text-primary mr-1" name="map-marker-alt" />
                        <Link to="/es/sucursales" className="text-primary" id="mm-es-locations-link">
                          Sucursales
                        </Link>{" "}
                        |{" "}
                        <Link to="/locations/atm" className="text-primary" id="mm-atms-link">
                          Cajeros automáticos
                        </Link>
                      </div>
                      <div className="text-nowrap">
                        <Icon lib="far" name="globe" class="text-primary mr-1" />
                        <Link to={altUrl} className="text-primary" id="mm-english-link">
                          English
                        </Link>
                      </div>
                    </div>
                  ) : (
                    <div className="w-100 d-flex align-items-center justify-content-between">
                      <button
                        id="mm-activate-search-modal"
                        onClick={() => props.setShowMobileSearch(true)}
                        className="btn-anchor-link"
                      >
                        <Icon lib="fal" name="search" />
                      </button>
                      <div className="d-flex align-items-center">
                        <Icon lib="far" name="map-marker-alt" class="text-primary mr-1" />
                        <Link to="/locations" className="text-primary" id="mm-en-locations-link">
                          Locations
                        </Link>{" "}
                        |{" "}
                        <Link to="/locations/atm" className="text-primary" id="mm-atms-link">
                          ATMs
                        </Link>
                      </div>
                      <div className="text-nowrap">
                        <Icon lib="far" name="globe" class="text-primary mr-1" />
                        <Link to={altUrl} className="text-primary" id="mm-spanish-link">
                          Español
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <div className="w-100 menu-item-bg p-3" id="mm-back-link" onClick={(e) => handleBackNavigation()}>
                <Icon lib="far" name="chevron-left" class="fa-sm anchor-color" />
                <span className="ml-2 anchor-link">{isSpanish ? "Regresa" : "Back"}</span>
              </div>
            )}
          </div>
        </div>
        {/* END Section Above Menus */}
        {/* Menus */}
        <div className="bg-white h-100 overflow-auto" style={{ paddingBottom: "110px" }}>
          {breadcrumbs[0] && (
            <Link
              to={breadcrumbs[0].landingUrl}
              className={`p-3 border-bottom d-block text-dark text-decoration-none menu-item-bg
                ${breadcrumbs[0].isActive ? "active" : ""}`}
              id={breadcrumbs[0].id}
            >
              <strong>{breadcrumbs[0].landingTitle ? breadcrumbs[0].landingTitle : breadcrumbs[0].title}</strong>
            </Link>
          )}
          {/* Map through currentMenuData */}
          {currentMenuData &&
            currentMenuData.map((data, index) => {
              return (
                // If data has a menus array, display arrows, else display just links
                data.menus ? (
                  // If data.title does not match anything in the featuredMenuItems array, display normally, else display it per the comps design
                  !featuredMenuItems.includes(data.title.toLowerCase()) ? (
                    <div
                      key={data.id}
                      className="p-3 border-bottom d-flex align-content-center menu-item-bg justify-content-between position-relative"
                    >
                      <a
                        href="/#"
                        className="mb-0 stretched-link text-black text-decoration-none"
                        onClick={(e) => handleForwardNavigation(e)}
                        id={data.id}
                        dangerouslySetInnerHTML={{ __html: data.title }}
                      />

                      <Icon lib="far" name="chevron-right" class="text-secondary mt-1 clickable" />
                    </div>
                  ) : (
                    <div className="bg-white" key={data.menus[index].id}>
                      <p className="p-3 mb-0 text-success font-weight-bold" id={data.id}>
                        <strong>{data.title}</strong>
                      </p>

                      {data.menus.map((menuItem) => (
                        <div className="menu-item-bg" key={menuItem.id}>
                          {menuItem.externalLink ? (
                            <a
                              href={menuItem.url}
                              className={`p-3 ml-2 text-dark d-block text-decoration-none ${
                                menuItem.isActive ? "active" : ""
                              }`}
                              id={menuItem.id}
                              dangerouslySetInnerHTML={{ __html: menuItem.title }}
                            />
                          ) : (
                            <Link
                              to={menuItem.url}
                              className={`p-3 ml-2 text-dark d-block text-decoration-none 
                              ${menuItem.isActive ? "active" : ""}`}
                              id={menuItem.id}
                              dangerouslySetInnerHTML={{ __html: menuItem.title }}
                            />
                          )}
                        </div>
                      ))}
                    </div>
                  )
                ) : (
                  <>
                    {data.externalLink ? (
                      <a
                        href={data.url}
                        className={`p-3 border-bottom text-dark d-block text-decoration-none menu-item-bg ${
                          data.isActive ? "active" : ""
                        }`}
                        id={data.id}
                        dangerouslySetInnerHTML={{ __html: data.title }}
                      />
                    ) : (
                      <Link
                        key={data.id}
                        to={data.url}
                        className={`p-3 border-bottom text-dark d-block text-decoration-none menu-item-bg ${
                          data.isActive ? "active" : ""
                        }`}
                        id={data.id}
                        dangerouslySetInnerHTML={{ __html: data.title }}
                      />
                    )}
                  </>
                )
              );
            })}
          {/* CTA Buttons */}
          {breadcrumbs.length === 0 && (
            <div className="p-3 bg-white">
              {isSpanish ? (
                <Link
                  to="/es/abrir-cuenta-bancaria-en-linea"
                  className="btn btn-primary d-block mb-3"
                  id="mm-open-account-button"
                >
                  Abrir una cuenta
                </Link>
              ) : (
                <>
                  <Button {...openAccountBtn} />
                  <Link
                    to="/personal-banking/contact-loan-officer"
                    className="btn btn-primary d-block mb-3"
                    id="mm-apply-for-a-loan-button"
                  >
                    Apply for a Loan
                  </Link>

                  <div>
                    <Icon lib="far" name="mobile" class="text-primary mr-2" />
                    <Link to="/personal-banking/online-banking#mobile-app-section" id="mm-mobile-banking-app-link">
                      Get the WaFd Mobile app
                    </Link>
                  </div>
                </>
              )}
            </div>
          )}
          {/* END CTA Buttons (End) */}
        </div>
        {/* END Menus */}
      </div>
      {/* END Menu Container */}
    </>
  );
};

export default MobileMenus;
